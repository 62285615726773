@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Geist';
  src: url('../public/fonts/geist/Geist.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DepartureMono';
  src: url('../public/fonts/DepartureMono-1.420/DepartureMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  :root {
    --background: 0 0% 96%; /* Slightly darker light grey for the main background */
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%; /* White for the reminder cards */
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --card-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-color: 0deg 0% 63%;
    --shadow-elevation-medium:
      0px 0.6px 0.6px hsl(var(--shadow-color) / 0.3),
      0px 1.4px 1.4px -1.2px hsl(var(--shadow-color) / 0.25),
      0.1px 3.9px 3.8px -2.3px hsl(var(--shadow-color) / 0.21),
      0.1px 10px 9.8px -3.5px hsl(var(--shadow-color) / 0.17);
  }

  .dark {
    --warm-gray-50: hsl(20 12% 98%);
    --warm-gray-100: hsl(20 12% 90%);
    --warm-gray-200: hsl(20 12% 80%);
    --warm-gray-300: hsl(20 12% 70%);
    --warm-gray-400: hsl(20 12% 60%);
    --warm-gray-500: hsl(20 12% 50%);
    --warm-gray-600: hsl(20 12% 40%);
    --warm-gray-700: hsl(20 12% 30%);
    --warm-gray-800: hsl(20 12% 20%);
    --warm-gray-900: hsl(20 12% 10%);
    --warm-gray-950: hsl(20 12% 6%);

    --background: 20 12% 6%;
    --foreground: 20 10% 98%;
    --card: 20 12% 8%;
    --card-foreground: 20 10% 98%;
    --popover: 20 12% 8%;
    --popover-foreground: 20 10% 98%;
    --primary: 20 89% 48%;
    --primary-foreground: 20 10% 98%;
    --secondary: 20 12% 12%;
    --secondary-foreground: 20 10% 98%;
    --muted: 20 12% 12%;
    --muted-foreground: 20 10% 70%;
    --accent: 20 12% 12%;
    --accent-foreground: 20 10% 98%;
    --border: 20 12% 16%;
    --input: 20 12% 16%;
    --ring: 20 89% 48%;
    --card-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.35), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
    --shadow-color: 20deg 12% 0%;
  }

  html, body, #root {
    font-family: 'Geist';
    font-weight: 400;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  /* Define your custom components here */
}

@layer utilities {
  .bg-background {
    background-color: hsl(var(--background));
  }
  .transition-fade {
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }

  .rounded-full {
    border-radius: 9999px;
  }

  /* Adjust the card content spacing */
  .card-content > * + * {
    margin-top: 0.5rem;
  }

  /* Style for the found truffle content */
  .bg-gray-100 {
    background-color: #f3f4f6;
  }

  .dark .bg-gray-800 {
    background-color: #1f2937;
  }
}

/* Custom styles */
html, body, #root {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.min-h-screen {
  min-height: 100vh; /* Fallback */
  min-height: 100dvh; /* Use dynamic viewport height if supported */
}

/* Add these styles for smoother transitions */
.absolute.inset-0 {
  will-change: opacity;
}

/* Toaster styles for top-right positioning */
.toaster {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
}

/* Center toasts on mobile */
@media (max-width: 640px) {
  .toaster {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

/* Add any additional custom styles below this line */

/* Add this at the end of the file */
input, textarea, select {
  font-size: 16px; /* Prevents zoom on iOS */
}

@media screen and (max-width: 768px) {
  input, textarea, select {
    font-size: 16px !important; /* Force 16px font size on mobile */
  }
}

.shadow-lg {
  box-shadow: var(--card-shadow);
}

/* Add this for the new compact button style */
.rounded-full {
  border-radius: 9999px;
}

/* Adjust the card content spacing */
.card-content > * + * {
  margin-top: 0.75rem;
}

/* Style for the found truffle content */
.bg-gray-100 {
  background-color: #f3f4f6;
}

.dark .bg-gray-800 {
  background-color: #1f2937;
}

/* Add these new styles at the end of the file */
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.gap-1 {
  gap: 0.25rem;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.space-x-1 > * + * {
  margin-left: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.custom-shadow {
  box-shadow: var(--shadow-elevation-medium);
}

@keyframes shinyOutline {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(99, 102, 241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0);
  }
}

.new-insight-effect {
  animation: shinyOutline 2s ease-out;
}

/* Add these styles */
.radix-popover-content-wrapper {
  z-index: 50;
}

.radix-popover-content {
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: popoverAnimation 0.2s ease-out;
}

@keyframes popoverAnimation {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes spring {
  0% {
    animation-timing-function: cubic-bezier(0.155, 0.415, 0.425, 1.295);
  }
  100% {
    transform: none;
  }
}

.ease-spring {
  animation-name: spring;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add this to the end of globals.css */
@keyframes ripple-ring {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 60, 0.4);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(255, 177, 60, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 177, 60, 0);
  }
}

.animate-ripple-ring {
  animation: ripple-ring 1.5s cubic-bezier(0.16, 1, 0.3, 1);
}
